/* Large devices (large desktops, 1200px and up) */

@media (min-width: $screen-xl-min) {
    .borders {
        ul {
            width: 100%;
            justify-content: space-evenly;
            align-items: center;
            justify-items: center;
        }
    }
}