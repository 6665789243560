// Placeholder text
@mixin placeholder($color) {
 	&::-moz-placeholder           { color: $color;   
                                  opacity: 1; }		 // Firefox
 	&:-ms-input-placeholder       { color: $color; } // Internet Explorer 10+
 	&::-webkit-input-placeholder  { color: $color; } // Safari and Chrome
}

@mixin border-custom-radius($topright: 0, $bottomright: 0, $bottomleft: 0, $topleft: 0) {
	-webkit-border-top-right-radius:    $topright;
	-webkit-border-bottom-right-radius: $bottomright;
	-webkit-border-bottom-left-radius:  $bottomleft;
	-webkit-border-top-left-radius:     $topleft;

	-moz-border-radius-topright:        $topright;
	-moz-border-radius-bottomright:     $bottomright;
	-moz-border-radius-bottomleft:      $bottomleft;
	-moz-border-radius-topleft:         $topleft;

	border-top-right-radius:            $topright;
	border-bottom-right-radius:         $bottomright;
	border-bottom-left-radius:          $bottomleft;
	border-top-left-radius:             $topleft;

	-moz-background-clip:    padding; 
	-webkit-background-clip: padding-box; 
	background-clip:         padding-box; 
}

// Image reflect
@mixin reflect ($length: 50%, $opacity: 0.2) {
    -webkit-box-reflect: below 0px -webkit-gradient(linear, left top, left bottom, from(transparent), color-stop($length, transparent), to(rgba(255,255,255,$opacity)));
}

// Vertical gradient, from top to bottom
//
// Creates two color stops, start and end, by specifying a color and position for each color stop.
// Color stops are not available in IE9 and below.
@mixin gradient($start-color, $end-color, $start-percent, $end-percent) {
	background-image: -webkit-linear-gradient(top, $start-color $start-percent, $end-color $end-percent);  // Safari 5.1-6, Chrome 10+
	background-image: -o-linear-gradient(top, $start-color $start-percent, $end-color $end-percent);  // Opera 12
	background-image: -moz-linear-gradient(top, $start-color $start-percent, $end-color $end-percent);
	background-image: -ms-linear-gradient(top, $start-color $start-percent, $end-color $end-percent);
	background-image: linear-gradient(to bottom, $start-color $start-percent, $end-color $end-percent); // Standard, IE10, Firefox 16+, Opera 12.10+, Safari 7+, Chrome 26+
	background-repeat: repeat-x;
	filter: e(%("progid:DXImageTransform.Microsoft.gradient(startColorstr='%d', endColorstr='%d', GradientType=0)",argb($start-color),argb($end-color))); // IE9 and down
}

// Horizontal gradient, from left to right
//
// Creates two color stops, start and end, by specifying a color and position for each color stop.
// Color stops are not available in IE9 and below.
@mixin horizontal-gradient($start-color, $end-color, $start-percent, $end-percent) {
    background-image: -webkit-linear-gradient(left, $start-color $start-percent, $end-color $end-percent); // Safari 5.1-6, Chrome 10+
    background-image: -o-linear-gradient(left, $start-color $start-percent, $end-color $end-percent); // Opera 12
    background-image: -moz-linear-gradient(left, $start-color $start-percent, $end-color $end-percent);
	background-image: -ms-linear-gradient(left, $start-color $start-percent, $end-color $end-percent);
    background-image: linear-gradient(to right, $start-color $start-percent, $end-color $end-percent); // Standard, IE10, Firefox 16+, Opera 12.10+, Safari 7+, Chrome 26+
    background-repeat: repeat-x;
    filter: e(%("progid:DXImageTransform.Microsoft.gradient(startColorstr='%d', endColorstr='%d', GradientType=1)",argb($start-color),argb($end-color))); // IE9 and down
}

// Other gradients
@mixin directional-gradient($start-color, $end-color, $deg) {
	background-repeat: repeat-x;
	background-image: -webkit-linear-gradient($deg, $start-color, $end-color); // Safari 5.1-6, Chrome 10+
	background-image: -o-linear-gradient($deg, $start-color, $end-color); // Opera 12
	background-image: linear-gradient($deg, $start-color, $end-color); // Standard, IE10, Firefox 16+, Opera 12.10+, Safari 7+, Chrome 26+
}
@mixin radial-gradient($inner-color, $outer-color) {
	background-image: -webkit-radial-gradient(circle, $inner-color, $outer-color);
	background-image: radial-gradient(circle, $inner-color, $outer-color);
	background-repeat: no-repeat;
}
@mixin striped-gradient ($color, $angle) {
	background-image: -webkit-linear-gradient($angle, $color 25%, transparent 25%, transparent 50%, $color 50%, $color 75%, transparent 75%, transparent);
	background-image: -o-linear-gradient($angle, $color 25%, transparent 25%, transparent 50%, $color 50%, $color 75%, transparent 75%, transparent);
	background-image: linear-gradient($angle, $color 25%, transparent 25%, transparent 50%, $color 50%, $color 75%, transparent 75%, transparent);
}
