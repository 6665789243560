/* Small devices (phone, up to 575px) */

@media (max-width: $screen-xs-max) {
    body {
        background-position: center;
        height: auto;
    }
    .borders {
        .flex_container {
            margin: 25px 5px;
            padding: 25px;
        }
        ul {
            flex-direction: column;
            flex: 1;
            li {
                padding: 15px 0;
                &:not(:last-child) {
                    border-bottom: 1px solid rgba($text-color, .5);
                }
                display: block;
            }
        }
    }
}